<template>
	<div>
    <v-btn 
      @click="handleExport" 
      color="primary"
      :loading="loading"
      :disabled="loading"
      elevation="0"
    >
      Exportar a excel
      <v-icon right>
        mdi-file-excel
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
import * as XLSX from "xlsx"
import api from '@/services/api'
import utils from '@/services/utils'
export default {
	props: {
		contentTypeID: {
			type: String,
			required: true,
		},
    config: {
      type: Object,
      required: true,
    },
	},
	data: () => ({
    loading: false,
		values: null,
    languages: null,
	}),
	computed: {
    /*
    languages () {
      return ['en', 'es', 'it', 'de ', 'pt', 'fr']
    },*/
		debug () {
      if (process.env.VUE_APP_ENV === 'development') return true
			return this.$route.query.debug && this.$route.query.debug.length > 0
		},
    weekDay () {
      return [
        'Lunes',
        'Martes',
        'Mi�rcoles',
        'Jueves',
        'Viernes',
        'S�bado',
        'Domingo',
      ]
    },
		customStyle () {
			let result = ''
			for (const row of this.rows){
				const color = row.color ? row.color : '#FFFFFF'
				result += `
										<Style ss:ID="row${row.id}">
              				<Alignment ss:Vertical="Top" />
											<Interior ss:Color="${color}" ss:Pattern="Solid"></Interior>
              				<Borders>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Right"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Left"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Top"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Bottom"/>
              				</Borders>
											<Font ss:Color="${utils.getOppositeColor(color)}"></Font>
              			</Style>
									`
			}
			return result
		},
	},
  mounted () {
  },
  methods: {
    getTranslation(v, locale, autoCompleteIfNull) {
      if (autoCompleteIfNull) return utils.getTranslation(v, locale)
      return v ? v[locale] : null
    },
    prepareDate (v) {
      if (!v) return
      const date = new Date(v)
      return `${utils.pad(date.getDate())}/${utils.pad((date.getMonth() + 1))}/${date.getFullYear()}`
    },
    convertTime (v) {
      if (!v) return '00:00'

      const h = v / 60 | 0
      const m = v - (h * 60)
      return `${h} : ${utils.pad(m)}`
    },
		prepareData (v) {
			const result = []
      for (const event of v) {
        const aux = {}

        //aux['D�a'] = event.Date	
        aux['D�a'] = this.prepareDate(event.Date)
        aux['Hora Inicio'] = event.Time ? this.convertTime(event.Time).split(' ').join('') : null
        aux['Hora Fin'] = event.TimeEnd ? this.convertTime(event.TimeEnd).split(' ').join('') : null
        for (const l of this.languages) {
          aux[`Actividad (${l})`] = this.getTranslation(JSON.parse(event.Title), l, true)
        }
        
        aux[`Locacion`] = this.getTranslation(JSON.parse(event.Place), 'es')

        aux['Imagen'] = event.Image
        aux['ID'] = event.ID
        aux['Imagen'] = event.Image
        
        for (const l of this.languages) {
          aux[`Descripci�n (${l})`] = event.Description ? this.getTranslation(JSON.parse(event.Description), l, true) : ''
        }
        aux[`Reservable`] = event.Reservable ? 'reservable' : null
        aux[`PVP alojado`] = event.Reservable ? event.PVPHosted : null
        aux[`Cupos`] = event.Reservable ? event.PAX : null
        /*
        aux['CategoryID'] = event.CategoryID
        for (const l of this.languages) {
          aux[`CategoryName (${l})`] = this.getTranslation(event.CategoryName, l, true)
        }
        */
        //aux[`PVP no alojado`] = event.Reservable ? event.PVPNoHosted : null
        /*
        for (const l of this.languages) {
          aux[`Ubicaci�n (${l})`] = this.getTranslation(JSON.parse(event.Place), l)
        }
        */
        result.push(aux)
      }

			return result
		},
		async handleExport () {
      this.loading = true
      this.languages = this.config.languages

      const url = `v1/public/activity-barcelo-01/${this.contentTypeID}/${this.config.id}/excel`
      api.getAll ('dviewcontent', url, 0, 1000, '')
        .then(response => {
          const aux = response.map(item => {
            if (this.config.category) item.CategoryName = this.config.category.filter(x => x.id === item.CategoryID).map(item => item.name).shift()
            return item
          })
          if (this.config.id === 'live-stage') {
            this.handleCreateExcel(`Calendario - Live Stage.xls`, 'Live Stage', aux)
          } else if (this.config.id === 'one') {
            this.handleCreateExcel(`Calendario - ONE.xls`, 'ONE', aux)
          } else {
            if (this.config.category) {
              for (const category of this.config.category) {
                console.log(response. category)
                this.handleCreateExcel(`Calendario - ${this.getTranslation(category.name, 'es')}.xls`, `${this.getTranslation(category.name, 'es')}`, response.filter(x => x.CategoryID === category.id))
              }
            }
          }
          this.loading = false
        })
        .catch (error => {
          console.log(error)
          this.loading = false
        })
    },
    handleCreateExcel (fileName, pageName, data) {
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const wb = XLSX.utils.book_new()

      const ws = XLSX.utils.json_to_sheet(this.prepareData(data))
      XLSX.utils.book_append_sheet(wb, ws, pageName)

			const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });

			const fileData = new Blob([excelBuffer], { type: fileType });
			var link = document.createElement("A")
			link.href = URL.createObjectURL(fileData)
			link.download = fileName
			//link.download = 'export.csv'
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
    handleGetData () {
    },
  },
}
</script>



